@import "../../../../node_modules/jstree/dist/themes/default/style";
@import "variables";

.jstree-default {
  .jstree-icon.jstree-ocl {
    background: unset;

    &:after {
      position: relative;
      font-family: "Font Awesome 5 Free";
      display: inline-block;
      padding-right: 3px;
      font-weight: 900;
      font-style: normal;
      top: -0.28em;
    }
  }

  .jstree-closed > .jstree-ocl:after {
    content: "\f105";
  }

  .jstree-open > .jstree-ocl:after {
    content: "\f107";
  }

  .jstree-leaf > .jstree-ocl:after {
    content: "";
  }

  .jstree-checkbox {
    border: .15em #515257 solid;
    border-radius: 3px;
    background: unset;
    color: #fff;
    margin-right: 0.3em;
  }

  &.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
  .jstree-checked > .jstree-checkbox {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23CDCED0' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: unset;
    background-position-x: center;
    background-position-y: center;
    background-size: 70% 70%;
    border-color: #CDCED0;
  }

  &.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover,
  .jstree-checked > .jstree-checkbox:hover {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23CDCED0' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: unset;
    background-position-x: center;
    background-position-y: center;
    background-size: 70% 70%;
    border-color: #CDCED0;
  }

  .jstree-hovered {
    background: unset;
    border-radius: unset;
    box-shadow: unset;
  }

  &.jstree-checkbox-no-clicked .jstree-clicked.jstree-hovered {
    background: unset;
  }

  .jstree-icon {
    width: unset;
    height: unset;
  }

  .jstree-icon:empty {
    width: 1rem;
    height: 1rem;
  }

  .jstree-anchor {
    line-height: 1em;
    height: 1em;
  }

  .jstree-anchor > .jstree-undetermined {
    background-color: #CDCED0;
    border: none;
    border-radius: 0.25rem;
  }

  .jstree-anchor > .jstree-undetermined:hover {
    background-color: #CDCED0;
    // border: .15em #515257 solid;
    border: none;
    border-radius: 0.25rem;
  }

  .jstree-disabled.jstree-clicked {
    background: none;
  }

  &> .jstree-container-ul .jstree-loading > .jstree-ocl {
    background: none;
  }

  .jstree-default-small > .jstree-container-ul .jstree-loading > .jstree-ocl {
    background: none;
  }

  .jstree-default-large > .jstree-container-ul .jstree-loading > .jstree-ocl {
    background: none;
  }

  .jstree-default-large .jstree-file {
    background: none;
  }

  .jstree-default-large .jstree-folder {
    background: none;
  }

  #jstree-dnd.jstree-default-large .jstree-er {
    background: none;
  }

  .jstree-node,
  .jstree-icon {
    background: none;
  }
}
