.datatable {
  td.user-edit {
    position: relative;
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        padding-top: 3px;
        padding-left: 9px;
      }
    }
  }
}

table#user-table tbody tr:hover td {
  background-color: #696969 !important;
}