#unit-subscription-edit-modal, #pid-subscription-modal {
  #timeInterval {
    display: inline-block;
    width: auto;
    min-width: 120px;
  }
}

#pid-subscription-table {
  tr [data-cell="delete"] i {
    display: none;
  }

  tr:hover [data-cell="delete"] i {
    display: inline-block;
  }
}

table#unit-subscription-table tbody tr:hover td {
  background-color: #696969 !important;
}

table#pid-subscription-table tbody tr:hover td {
  background-color: #696969 !important;
}


