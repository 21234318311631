// Current sass/auto-prefixes does not handle 'appearance'

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// CSS fix for Chrome browser autocomplete hiding input background image behind yellow background
// https://www.devinuk.com/en/css-fix-for-chrome-browser-autocomplete-hiding-input-background-image-behind-yellow-background/
@-webkit-keyframes autofill-valid {
  to {
    // Background image copied from: node_modules/bootstrap/dist/css/bootstrap.css selector: .was-validated .form-control:valid, .form-control.is-valid
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  }
}

@-webkit-keyframes autofill-invalid {
  to {
    // Background image copied from: node_modules/bootstrap/dist/css/bootstrap.css selector: .was-validated .form-control:invalid, .form-control.is-invalid
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E9020B' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  }
}

input.is-valid:-webkit-autofill {
  -webkit-animation-name: autofill-valid;
  -webkit-animation-fill-mode: both;
}

input.is-invalid:-webkit-autofill {
  -webkit-animation-name: autofill-invalid;
  -webkit-animation-fill-mode: both;
}

* {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: $input-focus-color;
  background-color: $input-focus-bg;
  border-color: $input-focus-border-color;
  outline: 0;
  @if $enable-shadows {
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
  } @else {
    box-shadow: $input-focus-box-shadow;
  }
}