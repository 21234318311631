#vehicleMap {
  width: auto;
  height: 300px;
}

ul.double {
  -webkit-columns: 60px 2;
  -moz-columns: 60px 2;
  columns: 60px 2;
  -moz-column-fill: auto;
  column-fill: auto;
}

div.tags > span {
  margin: 1px;
}

/* Gauge colors */
.primary-fill-green {
  fill: #28a745;
}

.primary-fill-red {
  fill: #dc3545;
}

.primary-fill-yellow {
  fill: #ffc107;
}

.primary-fill-grey {
  fill: #999;
}

.minWidth {
  min-width: 400px;
}

.selectedVehicle {
  background-color: #2b2d30 !important;
}

.selectedUnit {
  background-color: #2b2d30 !important;
}

table#vehicles-table tbody tr:hover td {
  background-color: #696969 !important;
}

table#units-table tbody tr:hover td {
  background-color: #696969 !important;
}

.vehicle-cards {
  margin: 0 0;
  justify-content: space-between;
  width: 100%;

  .card {
    margin: 0;

    &.vehicle-route-card {
      flex: 0 0 50% !important;
    }

    &.vehicle-data-usage {
      flex: 0 0 50% !important;
    }

    &.vehicle-last-feedback-card {
      flex: 0 0 100% !important;
    }

    &.vehicle-settings-card {
      flex: 0 0 50% !important;
    }
  }
}

.unit-gauges {
  margin: 0 0;
  width: 100%;

  .card {
    margin: 0;
  }

  &.unit-gauges-1, &.unit-gauges-2, &.unit-gauges-3, &.unit-gauges-4 {
    .card {
      flex: 0 0 100% !important;
    }
  }

  @media all and (min-width: 1376px) {
    &.unit-gauges-1 {
      .card {
        flex: 0 0 100% !important;
      }
    }

    &.unit-gauges-2 {
      .card {
        flex: 0 0 50% !important;
      }
    }

    &.unit-gauges-3 {
      .card {
        flex: 0 0 33.33% !important;
      }
    }

    &.unit-gauges-4 {
      .card {
        flex: 0 0 50% !important;
      }
    }
  }
}

.unit-cards {
  margin: 0 0;
  justify-content: space-between;
  width: 100%;

  &.card-deck {
    .card {
      margin: 0;

      &.unit-status-card, &.unit-data-usage-card, &.unit-configuration-card, &.unit-sensors-card, &.unit-revisions-card,
      &.unit-last-errors-card, &.unit-last-feedbacks-card, &.unit-files-card, &.unit-ignored-files-card {
        flex: 0 0 100% !important;
      }

      @media all and (min-width: 1376px) {
        &.unit-status-card {
          flex: 0 0 50% !important;
        }

        &.unit-data-usage-card {
          flex: 0 0 50% !important;
        }

        &.unit-configuration-card {
          flex: 0 0 50% !important;
        }

        &.unit-sensors-card {
          flex: 0 0 50% !important;
        }
      }
    }
  }
}

table.table-ellipsis {
  td.cell-ellipsis {
    position: relative;

    > span {
      position: absolute;
      left: 0;
      right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
