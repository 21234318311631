.flash-container {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 50%;
  margin-right: 10px;
  margin-top: 9px;
  max-width: 50vw;
  transform: translate(-50%);
  min-width: 350px;
}