@import "../../../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4";

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  justify-content: center !important;
  margin-top: 1rem !important;
}

.datatable {
  td {
    border-top: 0;
    border-bottom: 1px solid #38393F;
  }

  label {
    margin-bottom: 0 !important;
  }

  .odd {
    background-color: #38393F !important;
  }

  .even {
    background-color: #3E4045 !important;
  }

  .badge.total {
    font-size: 1.05em;
  }

  thead {
    th {
      &.custom-sorting-icons {
        &:before, &:after {
          content: unset !important;
        }
        i.custom-sorting-icons {
          font-size: 13px;
          margin-left: 4px;
          &:before {
            opacity: 0.3;
            content: "\2191";
          }
          &:after {
            opacity: 0.3;
            content: "\2193";
          }
        }

        &.sorting_asc {
          i.custom-sorting-icons:before {
            opacity: 1;
          }
        }

        &.sorting_desc {
          i.custom-sorting-icons:after {
            opacity: 1;
          }
        }
      }
    }
  }
}
