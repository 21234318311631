#feedback-filters-tab {
  .btn-filter {
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: .1rem;
    margin-right: .1rem;
    height: 2.5rem;
    &.active, &:hover {
      background-color: #3E4045;
    }
  }
  .filter-time-presets {
    span {
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

table.exported-files {
  td.download, td.delete {
    * {
      visibility: hidden;
    }
  }

  tr.exported-file:hover {
    td.download, td.delete {
      * {
        visibility: visible;
      }
    }
  }
}

table#vehicles tbody tr:hover td {
  background-color: #696969 !important;
}
