@import "bootstrap";
@import "fontawesome";
@import "selectize";
@import "flash";
@import "side-menu";
@import "fixes";
@import "datatable";
@import "jstree";
@import "users";
@import "react-modal";
@import "media";
@import "flatpickr";
@import "leaflet";
@import "bootstrap-colorpicker";
@import "accordion";
@import "unit-subscription";
@import "feedbacks";
@import "fontello/luminator-aw.css";
@import "scrollbar";
@import "filearchive";
@import "fineuploader";
@import "vehicleTags";
@import "vehicles";
@import "summernote.css";
@import "messages";
@import "index";
@import "dashboard";
@import "units";
@import "companies";
@import "mediaplayers";
@import "mediaplayerFamilies";
@import "maw2b";
@import "dynamicType";

html, body {
  min-height:100vh;
  width: 100%;
  padding: 0;
  margin: 0;
}

footer {
  height: 45px;
}

header {
  height: 55px; // 3.5em
  background-color: #25272D;
  nav {
    height: 100%;
    .user {
      i.fa {
        margin-top: 3px;
      }
      span {
        padding: 0 2px;
      }
    }
  }
}

main {
  height: calc(100vh - 4rem);
}

a:hover {
  text-decoration: none;
}

.main-content {
  min-height: 100%;
  background-color: #2E3035;
  overflow-y: auto;

  .section-bar {
    height: 50px;
    font-size: 1.1rem;
  }
}

.badge {
  border-radius: 6px;
}

.modal-header {
  background-color: $modal-header-bg;
}

.nav-tabs .nav-link {
  color: #C8C9CA;
  border-top: 3px solid transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-top: 3px solid $primary;
}

.tab-content {
  background-color: #38393F;
}

.nav-tabs {
  margin-bottom: 3px;
  .nav-item {
    cursor: pointer;
  }
}

.btn {
  text-transform: uppercase;
}

table {
  .table-hover {
    tbody tr {
      @include hover {
        color: $table-hover-color !important;
        background-color: $table-hover-bg !important;
      }
    }
  }

  th {
    white-space: nowrap;
  }
}

.modal-title {
  font-size: $h5-font-size !important;
}

.pointer {
  cursor: pointer;
}

.custom-control-label {
  cursor: pointer;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #CDCED0;
}

.tag-color-0 {	list-style-image: url("/assets/images/tag-color-0.png"); }
.tag-color-1 {	list-style-image: url("/assets/images/tag-color-1.png"); }
.tag-color-2 {	list-style-image: url("/assets/images/tag-color-2.png"); }
.tag-color-3 {	list-style-image: url("/assets/images/tag-color-3.png"); }
.tag-color-4 {	list-style-image: url("/assets/images/tag-color-4.png"); }
.tag-color-5 {	list-style-image: url("/assets/images/tag-color-5.png"); }
.tag-color-6 {	list-style-image: url("/assets/images/tag-color-6.png"); }
.tag-color-7 {	list-style-image: url("/assets/images/tag-color-7.png"); }

.float-end {
  display: block;
  float: right;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Feedback filters...
span.dropdown-toggle::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-style: normal;
  content: "\f078";
  color: $text-muted;
  border-color: transparent;
}

.noHover:hover {
  text-decoration: none;
  color: inherit;
}