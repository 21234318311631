.faIcon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.maintenance:before{
  content: "\f071";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #ffc107;
  margin-right: 3px;
}

.features:before{
  content: "\f005";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #ffc107;
  margin-right: 3px;
}

.info:before{
  content: "\f05a";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #17a2b8;
  margin-right: 3px;
}

.uploadFile {
    color: transparent;
    margin: 0px !important;
    padding: 0px !important;
    width: 90px;
}

table#message-table tbody tr:hover td {
  background-color: #696969 !important;
}