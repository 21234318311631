$widget-height: 205px;
$widget-header-height: 40px;
$widget-width: calc(#{$widget-height} + #{$widget-header-height});

.dashboard {
  margin-bottom: $grid-gutter-width / 2;
  .dashboard-row {
    display: flex;
    flex-wrap: wrap;
    .dashboard-widget {
      // display: none;
      width: $widget-width;
      min-width: $widget-width;
      max-width: $widget-width;
      margin-right: $grid-gutter-width / 2;
      padding-top: $grid-gutter-width / 2;
      &:last-child {
        margin-right: 0;
      }
      &.dashboard-widget-3w {
        width: calc(#{$widget-width} * 3);
        min-width: calc(#{$widget-width} * 3);
        max-width: calc(#{$widget-width} * 3);
      }
      &.dashboard-widget-4w {
        width: calc(#{$widget-width} * 4 + #{$grid-gutter-width} / 2);
        min-width: calc(#{$widget-width} * 4 + #{$grid-gutter-width} / 2);
        max-width: calc(#{$widget-width} * 4 + #{$grid-gutter-width} / 2);
      }
      .dashboard-widget-header {
        @extend .card-header;
        height: $widget-header-height;
        min-height: $widget-header-height;
        max-height: $widget-header-height;
      }
      .dashboard-widget-body {
        @extend .card-body;
        background-color: #3E4045;
        height: $widget-height;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.healthFleet {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: 100%;
  max-width: 540px;
}
