$gray-100: #F8F9FA;
$gray-200: #EBEBEB;
$gray-300: #DEE2E6;
$gray-400: #CED4DA;
$gray-500: #ADB5BD;
$gray-600: #999999;
$gray-700: #444444;
$gray-800: #303030;
$gray-900: #222222;
$white: #FFFFFF;
$black: #000000;
$blue: #375A7F;
$indigo: #6610F2;
$purple: #6F42C1;
$pink: #E83E8C;
$red: #E74C3C;
$orange: #FD7E14;
$yellow: #F39C12;
$green: #00BC8C;
$teal: #20C997;
$cyan: #3498DB;
$primary: #24C1D7;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-600;
$dark: #45474B;
$body-bg: #1D1E23;
$body-color: #FFFFFF;
$font-family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$card-cap-bg: #515257;
$card-bg: #38393F;
$card-inner-border-radius: 0;
$card-spacer-x: 0.85rem;
$card-spacer-y: 0.45rem;
$link-color: lighten($primary, 25%);
$enable-rounded: true;
$border-radius: 3px;
$enable-shadows: false;
$nav-pills-link-active-bg: #515257;
$dropdown-bg: #3B3C41;
$dropdown-border-color: #26272C;
$dropdown-divider-bg: #26272C;
$dropdown-link-color: $white;
$dropdown-link-hover-color: #FFFFFF;
$dropdown-link-hover-bg: #515257;
$dropdown-link-active-bg: #515257;
$badge-padding-y: .75em;
$badge-padding-x: 1em;
$pagination-color: $white;
$pagination-bg: #515257;
$pagination-border-width: 0;
$pagination-border-color: transparent;
$pagination-hover-color: $white;
$pagination-hover-bg: lighten(#515257, 10%);
$pagination-hover-border-color: transparent;
$pagination-active-bg: $pagination-hover-bg;
$pagination-active-border-color: transparent;
$pagination-disabled-color: $white;
$pagination-disabled-bg: darken(#515257, 15%);
$pagination-disabled-border-color: transparent;
$font-size-base: 0.9375rem;
$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem;
$modal-content-bg: #2F3035;
$modal-content-border-color: #26272C;
$modal-header-bg: #38393E;
$modal-header-border-color: #26272C;
$nav-tabs-border-width: 0;
$nav-tabs-border-color: unset;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-bg: unset;
$nav-tabs-link-active-border-color: unset;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: #515257;
$custom-control-indicator-bg: transparent;
$custom-control-indicator-border-color: #515257;
$custom-control-indicator-border-width: 2px;
$custom-control-indicator-checked-color: #CDCED0;
$custom-control-indicator-checked-border-color: #CDCED0;
$custom-control-indicator-checked-bg: transparent;
$custom-control-indicator-disabled-bg: transparent;
$custom-control-indicator-checked-disabled-bg: transparent;
$hr-border-color: #535353;
$hr-border-width: 1px;
$table-accent-bg: #3E4045;
$table-border-color: #38393F;
$input-border-color: transparent;
$input-group-addon-color: $gray-500;
$input-group-addon-bg: $gray-700;
$custom-file-color: $gray-500;
$custom-file-border-color: $gray-700;
$nav-link-padding-x: 2rem;
$nav-link-disabled-color: $gray-500;
$nav-tabs-border-color: $gray-700;
$navbar-padding-y: 1rem;
$navbar-dark-color: rgba($white, .6);
$navbar-dark-hover-color: $white;
$navbar-light-color: $white;
$navbar-light-hover-color: $success;
$navbar-light-active-color: $white;
$navbar-light-disabled-color: rgba($white, .3);
$navbar-light-toggler-border-color: rgba($white, .1);
$jumbotron-bg: #38393F;
$popover-bg: $gray-800;
$popover-header-bg: $gray-700;
$progress-height: 0.625rem;
$progress-font-size: 0.625rem;
$progress-bg: $gray-700;
$list-group-bg: $gray-800;
$list-group-border-color: $gray-700;
$list-group-hover-bg: $gray-700;
$breadcrumb-bg: #38393F;
$close-color: $white;
$close-text-shadow: none;
$pre-color: inherit;
$yiq-contrasted-threshold: 175;
