.tag-color-0 { color: #F05A5A }
.tag-color-1 { color: #EEBE36 }
.tag-color-2 { color: #068ED1 }
.tag-color-3 { color: #f6f6f6 }
.tag-color-4 { color: #A49393 }
.tag-color-5 { color: #67BC89 }
.tag-color-6 { color: #CD569A }
.tag-color-7 { color: #AB78C4 }

.fa-status-gap {
  margin-right: 3px
}