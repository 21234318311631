#files {
  .show-on-hover {
    visibility: hidden;
  }

  tr:hover {
    .show-on-hover {
      visibility: visible;
    }
  }

  th.sorting {
    position: relative;
    cursor: pointer;
    padding-right: 30px;

    .sorter {
      margin-left: 3px;
      font-size: 13px;

      &:before {
        right: 1em;
        content: "\2191";
      }

      &:after {
        right: 0.5em;
        content: "\2193";
      }

      &:before, &:after {
        opacity: 0.3;
      }
    }

    &.desc {
      .sorter:after {
        opacity: 1;
      }
    }

    &.asc {
      .sorter:before {
        opacity: 1;
      }
    }
  }

  td {
    vertical-align: middle;
  }
}

.files-react {
  .drop-label {
    display: none;
  }

  .drop-area {
    visibility: visible;
    top: 0;
    left: 0;
  }

  &.qq-upload-drop-area-active {
    .drop-label {
      display: block;
      top: 0;
      left: 0;
      width: 100%;
    }

    .drop-area {
      visibility: hidden;
    }
  }
}

.qq-total-progress-bar.done {
  width: 100% !important;
}

#uploadArea {
  &>div {
    display: flex;
    flex: 1 1 auto !important;
  }

  .qq-hide {
    display: none;
  }

  .qq-upload-button {
    white-space: nowrap !important;
    overflow: unset !important;
  }

  .qq-upload-drop-area {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 30px;
    z-index: 2;
  }
}
