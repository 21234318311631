@import "variables";
@import "../../../../node_modules/selectize-bootstrap4-theme/dist/css/selectize.bootstrap4";
@import "../../../../node_modules/react-selectize/themes/index";

.form-control.selectize-control .selectize-input {
  font-size: $font-size-base;
  border-radius: $border-radius;
  border: $input-border-width solid $input-border-color;
  height: $input-height;
}

.form-control.selectize-control.multi .selectize-input {
  min-height: 38px;
}

.form-control.selectize-control.single.is-invalid:after {
  border-color: #E74C3C;
  border-style: none;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E74C3C' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23E74C3C' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  /* background-position: center right calc(.375em + .1875rem); */
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  height: calc(2.25rem + 2px);
  right: -0.45rem;
  margin-top: -1.97063rem;
  top: unset;
}

.form-control.selectize-control .option.disabled {
  pointer-events: none;
}

.selectButton {
  background-color: #24C1D7;
  color: #fff;
  border-color: #24C1D7;
  display: inline-block;
  font-size: 75%;
  font-weight: 400;
  line-height: 1;
  padding: 0.25em 0.4em;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 0.25rem;
  margin-left: 3px;
  cursor: default;
}

.react-selectize {
  color: #495057 !important;
  font-family: unset !important;

  &.root-node {
    width: auto;
  }

  &.open {
    .react-selectize-control {
      border-bottom-color: transparent !important;
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  &.is-invalid {
    .react-selectize-control {
      border-color: #E74C3C !important;
      padding-right: calc(1.5em + .75rem) !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E74C3C' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23E74C3C' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
      background-repeat: no-repeat !important;
      background-position: center right calc(.375em + .1875rem) !important;
      background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
      .react-selectize-toggle-button-container:after {
        content: none;
      }
    }
  }

  .simple-option {
    color: black !important;
  }

  .react-selectize-control {
    font-size: $font-size-base !important;
    border-radius: $border-radius !important;
    border: $input-border-width solid $input-border-color !important;
    height: $input-height !important;
    cursor: default !important;
    background-clip: padding-box !important;
  }

  .dropdown-menu {
    display: block !important;
    background-color: #FFF !important;
    background-clip: padding-box !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: #CED4BD !important;
    border-radius: 0 0 0.25rem 0.25rem !important;
    border-top-width: 0 !important;
    color: #212529 !important;
    font-size: 1rem !important;
    max-height: 14.5rem !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 0.5rem 0 !important;
    text-align: left !important;
    width: calc(100% - 2px) !important;
    margin-left: 1px;
    -webkit-overflow-scrolling: touch !important;

    .option-wrapper {
      .simple-option {
        font-weight: 400 !important;
        padding: 0.25rem 1.5rem !important;
        text-align: inherit !important;
        white-space: nowrap !important;
      }

      &.highlight {
        text-decoration: none !important;
        background-color: #007bff !important;
        .simple-option {
          color: #fff !important;
        }
      }
    }
  }

  .react-selectize-search-field-and-selected-values {
    margin-top: -2px;
    input {
      color: #495057 !important;
    }
  }

  .react-selectize-reset-button-container {
    display: none !important;
  }

  .react-selectize-toggle-button-container {
    &:after {
      border-color: #212529 transparent transparent transparent;
      border-style: solid;
      border-width: 0.54127rem 0.3125rem 0 0.3125rem;
      content: '';
      display: block;
      height: 0;
      margin-top: -0.27063rem;
      position: absolute;
      top: 50%;
      right: 0.75rem;
      width: 0;
    }

    .react-selectize-toggle-button {
      display: none;
    }
  }
}
