#edit-dynamic-type table {
  .show-on-hover {
    visibility: hidden;
  }

  tr:hover {
    .show-on-hover {
      visibility: visible;
    }
  }
}