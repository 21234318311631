.ReactModalPortal {
  z-index: 999 !important;

  .ReactModal__Overlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    background-color: rgba(0, 0, 0, .5) !important;
  }

  .ReactModal__Content {
    position: absolute !important;
    border: unset !important;
    background: unset !important;
    overflow: visible !important;
    -webkit-overflow-scrolling: touch !important;
    border-radius: unset !important;
    outline: none !important;
    top: 50% !important;
    left: 50% !important;
    right: auto !important;
    bottom: auto !important;
    margin-right: -50% !important;
    padding: 0 !important;
    transform: translate(-50%, -50%) !important;

    .modal-content {
      @include media-breakpoint-up(sm) {
        width: $modal-md !important;
      }
    }
  }
}
