@import "variables";

header {
  .expand {
    padding: 0 !important;
    margin: 0 !important;
    height: 48px !important;
    width: 53px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer;
    color: $text-muted;
    &:hover {
      color: $white;
    }
    i.fa {
      padding: 0 !important;
      margin: 0 !important;
      font-size: 1.1rem;
    }
  }
}

#side-menu {
  min-width: 245px;
  max-height: calc(100vh - 55px);
  background-color: #38393F;

  .groups {
    overflow-y: auto;
  }

  a.nav-link {
    padding-left: 1rem;
    line-height: 19px;
    color: #CBCBCD;
    padding-right: 12px;
    border-left: 3px solid transparent;

    &:hover {
      background-color: #515257;
      border-radius: 0;
    }

    &.active {
      border-radius: 0;
      border-left: 3px solid $primary;
      color: $white;
      background-color: #515257;

      &:after {
        font-family: "Font Awesome 5 Free";
        content: "\f054";
        display: inline-block;
        padding-right: 3px;
        vertical-align: middle;
        font-weight: 900;
        text-align: right;
        float: right;
        margin-top: 2px;
      }
    }

    > span {
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 145px;
    }
  }

  .current-page-name {
    text-transform: initial;
    line-height: 50px;
    font-weight: bold;
    font-size: 1.1rem;
  }

  .icon {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }

  .credits {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    font-size: .8em;
  }

  .expand {
    display: none;
  }

  .group {
    text-transform: uppercase;
    font-weight: bold;
    font-size: .8rem;
    color: #9A9A9E;
    margin-top: 20px;
    margin-bottom: 3px;
    &.first {
      margin-top: 10px;
    }
  }

  &.minimized {
    min-width: 51px;
    .groups {
      .group {
        display: none !important;
      }

      a.nav-link {
        text-transform: initial;
        padding: 0 !important;
        height: 48px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        &.active {
          &:after {
            display: none !important;
          }
        }
      }

      .label {
        display: none !important;
      }
      .icon {
        font-size: 1.1rem !important;
        width: unset !important;
        margin: 0 !important;
      }
    }

    .current-page-name {
      display: none !important;
    }

    .credits {
      display: none !important;
    }
  }
}
