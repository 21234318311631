@import "../../../../node_modules/leaflet/dist/leaflet";
@import "../../../../node_modules/leaflet.awesome-markers/dist/leaflet.awesome-markers";

/* Make it dark */

.leaflet-tile {
  // filter: grayscale(100%);
  filter: grayscale(100%) invert(100%);
}

.awesome-marker {
  background: url('/vassets/images/granite/markers/markers-soft.png') no-repeat 0 0;
  i.fa {
    display: none; // it does not look good at all...
  }
}

.awesome-marker-shadow {
  background: url('/vassets/images/granite/markers/markers-shadow.png') no-repeat 0 0;
}

@media (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .awesome-marker {
    background-image: url('/vassets/images/granite/markers/markers-soft.png');
    background-size: unset;
  }
  .awesome-marker-shadow {
    background-image: url('/vassets/images/granite/markers/markers-shadow.png');
    background-size: unset;
  }
}
