@import "../../../../node_modules/video-react/styles/scss/video-react.scss";

.playlistTable {
  white-space: nowrap;
  table-layout: fixed;

  colgroup {
    col:nth-child(1) { width: 50px; }
    col:nth-child(2) { width: 50px; }
    col:nth-child(3) { width: 160px; }
    col:nth-child(4) { }
    col:nth-child(5) { width: 85px; }
    col:nth-child(6) { width: 250px; }
    col:nth-child(7) { width: 40px; }
    col:nth-child(8) { width: 40px; }
    col:nth-child(9) { width: 85px; }
  }

  .sorting {
    td:nth-child(1) { width: 50px; }
    td:nth-child(2) { width: 50px; visibility: hidden; }
    td:nth-child(3) { width: 160px; }
    td:nth-child(4) { }
    td:nth-child(5) { display: none; }
    td:nth-child(6) { display: none; }
    td:nth-child(7) { display: none; }
    td:nth-child(8) { display: none; }
    td:nth-child(9) { display: none; }
  }

  th {
    &.checkboxCol {
      * {
        cursor: pointer;
      }
    }
  }

  tbody {
    tr {
      height: 95px;
    }
  }

  td {
    vertical-align: middle;

    &.sortDragCol {
      text-align: center;
      i {
        color: $custom-control-indicator-border-color;
        cursor: ns-resize;
      }
    }

    &.checkboxCol {
      text-align: center;
      * {
        cursor: pointer;
      }
    }

    &.infoCol {
      vertical-align: top;
    }

    &.durationCol {
      span {
        &.clickable {
          cursor: pointer;
          i.fa {
            visibility: hidden;
          }
        }
      }
    }

    &.conditionCol {
      .btn.add-condition {
        visibility: hidden;
      }
    }

    &.publishCol {
      text-align: center;
      i {
        color: $custom-control-indicator-border-color;
        &.active {
          color: $success;
        }
      }
    }

    &.priorityCol {
      text-align: center;
      i {
        color: $custom-control-indicator-border-color;
        &.active {
          color: $yellow;
        }
      }
    }
  }

  tr {
    &:hover {
      td {
        &.conditionCol {
          .btn.add-condition {
            visibility: visible;
          }
        }

        &.durationCol {
          .clickable {
            i.fa {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.thumbnail-preview {
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    backface-visibility: hidden;
    opacity: 1;
  }
}

.media-info-detail {
  td:nth-child(1) {
    width: 50px;
    font-weight: bold;
    text-align: center;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

#mediaItem-dialog {
  &.tab-0, &.tab-2  {
    max-width: 1000px !important;
    width: calc(100vw - 15em) !important;
  }

  &.tab-1  {
    max-width: 600px !important;
    width: calc(100vw - 15em) !important;
  }
}

#rtsp-dialog {
  .name-text {
    position: absolute;
    right: 20px;
    line-height: 35px;
  }
  .duration-input {
    padding-right: 0px;
  }
  .duration-text {
    padding-left: 10px;
    small {
      margin-top: 40px;
    }
  }
}

#rtsp-container {
  margin-top: 20px;
  .name-text {
    position: absolute;
    right: 20px;
    line-height: 35px;
  }
  .duration-input {
    padding-right: 0px;
  }
  .duration-text {
    padding-left: 10px;
    small {
      margin-top: 40px;
    }
  }
  button {
    float: right;
  }
}

#archive-dialog {
  max-width: 1000px !important;
  width: calc(100vw - 15em) !important;

  .card-deck {
    .card {
      cursor: pointer;
      &:hover {
        i.download {
          font-size: 1.5rem;
          color: $white;
        }
      }
    }
  }

  .archiveFileList {
    overflow-y: auto;
    max-height: 60vh;
    table {
      tr {
        height: 4rem;
        color: $text-muted;
        cursor: pointer;
        &.selected {
          td {
            background-color: #444;
            color: $white;
          }
        }
        &:hover {
          color: $white;
        }
      }
      td {
        vertical-align: middle;
        &:nth-child(1) {
          width: 200px;
        }
        &:nth-child(2) {
          i.fa {
            font-size: 1.5rem;
            cursor: pointer;
          }
        }
        &:nth-child(3) {
          width: 100px;
          text-align: right;
        }
        &:nth-child(4) {
          text-align: right;
          padding-right: 1rem;
          width: 180px;
        }
      }
    }
  }

  .archiveFileGrid {
    overflow-y: auto;
    max-height: 60vh;

    .card-body {
      height: 120px;
      width: 120px;
      font-size: 70px;
      text-align: center;
    }

    .card-footer {
      small {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(120px - 1.25rem * 2);
        display: block;
        overflow: hidden;
      }
    }
    .ghost {
      width: calc(#{$card-deck-margin} * 2 + 120px);
      padding: 0;
      margin: 0;
    }
  }
}

#provider-dialog {
  .modal-body {
    ul {
      list-style-type: none;
    }
  }
}

#providerList {
  .card-deck {
    .card {
      cursor: pointer;
      .card-img-top {
        background-color: white;
        img {
          max-height: 118px;
          max-width: 118px;
          padding: 10px;
        }
      }
      .card-footer {
        text-align: center;
        padding-left: .9rem;
        padding-right: .9rem;
      }
    }
  }
}

#playlist-sort-dialog {
  .modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }
}

li.sort-playlist {
  cursor: pointer;
  .even {
    background-color: #38393F !important;
  }

  .odd {
    background-color: #3E4045 !important;
  }

  .fa-grip-lines {
    color: $custom-control-indicator-border-color;
    cursor: ns-resize;
  }
}