$fa-font-path: './fontawesome/webfonts';
@import "fontawesome/scss/fontawesome";
@import "fontawesome/scss/brands";
@import "fontawesome/scss/solid";
@import "fontawesome/scss/regular";
@import "fontawesome/scss/icons";

.fa-spinner::before {
  content: "\f1ce" !important;
}
