table#units-admin-table tbody tr td span i.pen{
  content:"\f303";
  visibility: hidden;
}

table#units-admin-table tbody tr:hover td span i.pen:before{
  content:"\f303";
  visibility: visible;
}

table#units-admin-table tbody tr:hover td {
  background-color: #696969 !important;
}