@import "../../../../node_modules/flatpickr/dist/flatpickr";

.flatpickr-calendar {
  input[type=number] {
    -moz-appearance:textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  .flatpickr-month {
    height: 47px;
  }

  .flatpickr-weekdaycontainer {
    display: flex;
    .flatpickr-weekday {
      width: 44px;
    }
  }

  .flatpickr-day {
    border-radius: $border-radius;
    &.inRange {
      border-radius: 0;
    }
    line-height: 37px;
    width: 39px;
    margin: 0;
    max-width: unset;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: $primary;
    color: $white;
    border-color: $primary;
  }

  .flatpickr-day.startRange {
    border-radius: $border-radius 0 0 $border-radius;
  }

  .flatpickr-day.endRange {
    border-radius: 0 $border-radius $border-radius 0;
  }

  .flatpickr-monthDropdown-months {
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    color: #444;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .flatpickr-current-month {
    .numInputWrapper {
      padding: 0.375rem 0;
      font-size: 0.9375rem;
      margin-left: 0.5rem;
      min-width: 60px;
    }
  }

  .flatpickr-weekdays, .flatpickr-month, .flatpickr-weekday {
    background-color: #38393E;
    color: $white !important;
  }

  .flatpickr-prev-month, .flatpickr-next-month {
    svg {
      fill: $white;
    }
  }

  &:before, &:after {
    display: none;
  }

  .flatpickr-time {
    border-radius: $border-radius;
  }
}
