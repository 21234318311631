@import url('https://fonts.googleapis.com/css?family=Roboto:100&display=swap');

.progress-round {
  width: 150px;
  height: 150px;
  background: none;
  position: relative;
}

.progress-round::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid #373a40;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-round>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress-round .progress-left {
  left: 0;
}

.progress-round .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 3px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress-round .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress-round .progress-right {
  right: 0;
}

.progress-round .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress-round .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}

.roboto {
  font-family: 'Roboto', sans-serif;
  font-weight: 100 !important;
}

.getVehicle {
  margin-right: 3px;
}

.text-success {
  color: #28a745;
}

.text-warning {
  color: #ffc107;
}

.text-danger {
  color: #dc3545;
}

a.text-success:visited {
  color: #28a745;
}

a.text-warning:visited {
  color: #ffc107;
}

a.text-danger:visited {
  color: #dc3545;
}