.accordion {
  .card-header {
    cursor: pointer;
    h5 {
      display: inline-block;
    }
    &:before {
      font-family: "Font Awesome 5 Free";
      display: inline-block;
      font-weight: 900;
      font-style: normal;
      content: "\f078";
      cursor: pointer;
    }

    &.collapsed {
      &:before {
        content: "\f077";
      }
    }
  }
}